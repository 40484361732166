import { useCallback, useEffect, useState } from 'react';
import {
  GridToolbarQuickFilter,
  GridToolbarQuickFilterProps,
} from '@mui/x-data-grid-premium';
import { debounce } from 'lodash';
import { FilterIds } from '../FilterPanel';
import { useURLFilters } from 'hooks/useURLFilters';

interface Props {
  useCustomFilterModel: boolean;
}

const SearchInput = ({ useCustomFilterModel }: Props) => {
  const { filters, updateFilterModelItems } = useURLFilters();
  const nameFilter = filters.find(
    (filter) => filter.columnField === FilterIds.NAME
  );
  const [quickFilterText, setQuickFilterText] = useState(
    nameFilter?.value ?? ''
  );

  useEffect(() => {
    setQuickFilterText(nameFilter?.value ?? '');
  }, [nameFilter]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedSetParams = useCallback(
    debounce((value: string) => {
      updateFilterModelItems({ [FilterIds.NAME]: value });
    }, 500),
    []
  );

  const handleQuickFilterChange = (value: string) => {
    setQuickFilterText(value);

    debouncedSetParams(value);
  };

  const controlledInputProps: Partial<GridToolbarQuickFilterProps> = {
    onChange: (event) => {
      handleQuickFilterChange(event.target.value);
    },
    value: quickFilterText,
  };

  return (
    <GridToolbarQuickFilter
      variant="outlined"
      size="small"
      sx={{ pb: 0 }}
      {...(useCustomFilterModel ? controlledInputProps : {})}
    />
  );
};

export default SearchInput;
