import { Tag as TagSchema } from '@automata/api/apiSchemas';
import { TagSelector } from '@automata/ui';
import {
  useAddTagToWorkflow,
  useAddTagToRunHistory,
  useCreateTag,
  useRemoveTagFromWorkflow,
  useRemoveTagFromRunHistory,
  useGetWorkspaceTags,
} from '@automata/api/apiComponents';
import { useWorkspaceID } from 'hooks/useWorkspaceID';
import { selectRandomCategoricalColor } from '@automata/utils';
import { useQueryClient } from '@tanstack/react-query';

export type TagSelectionManagerProps = {
  currentTags: TagSchema[];
  id: string;
  resource: 'workflow' | 'run';
};

export const TagSelectionManager = ({
  currentTags,
  id,
  resource,
}: TagSelectionManagerProps): JSX.Element | null => {
  const workspaceID = useWorkspaceID();
  const queryClient = useQueryClient();

  const {
    data: allTags,
    isLoading: isLoadingAllTags,
    error: tagsError,
  } = useGetWorkspaceTags({
    pathParams: {
      workspaceID: workspaceID ?? '',
    },
  });

  // Filter out tags that are archived or already applied to the resource
  const tagsListToRender = allTags?.tags.filter(
    (tag) =>
      !currentTags.some((currentTag) => currentTag.id === tag.id) &&
      !tag.is_archived
  );

  const triggerWorkflowsRefetch = () => {
    queryClient.invalidateQueries({
      predicate: (query) => query.queryKey.some((key) => key === 'workflows'),
    });
  };

  const triggerRunsRefetch = () => {
    queryClient.invalidateQueries({
      predicate: (query) =>
        query.queryKey.some((key) => key === 'run-histories'),
    });
  };

  const { mutate: addTagToWorkflow } = useAddTagToWorkflow();
  const { mutate: addTagToRunHistory } = useAddTagToRunHistory();
  const { mutate: createTag } = useCreateTag();
  const { mutate: removeTagFromWorkflow } = useRemoveTagFromWorkflow();
  const { mutate: removeTagFromRun } = useRemoveTagFromRunHistory();

  const removeTag = (tag: TagSchema) => {
    if (resource === 'run') {
      removeTagFromRun(
        {
          pathParams: { runHistoryID: id, tagID: tag.id },
        },
        {
          onSuccess: () => {
            triggerRunsRefetch();
          },
        }
      );
    }
    if (resource === 'workflow') {
      removeTagFromWorkflow(
        {
          pathParams: { workflowID: id, tagID: tag.id },
        },
        {
          onSuccess: () => {
            triggerWorkflowsRefetch();
          },
        }
      );
    }
  };

  const addTag = (tag: TagSchema) => {
    if (tag.id === '') {
      createTag(
        {
          pathParams: { workspaceID: workspaceID ?? '' },
          body: { name: tag.name, color: selectRandomCategoricalColor() },
        },
        {
          onSuccess: (newTag) => {
            if (resource === 'run') {
              addTagToRunHistory(
                {
                  pathParams: { runHistoryID: id, tagID: newTag.id },
                },
                {
                  onSuccess: () => {
                    triggerRunsRefetch();
                  },
                }
              );
            }
            if (resource === 'workflow') {
              addTagToWorkflow(
                {
                  pathParams: { workflowID: id, tagID: newTag.id },
                },
                {
                  onSuccess: () => {
                    triggerWorkflowsRefetch();
                  },
                }
              );
            }
          },
        }
      );
    } else {
      if (resource === 'run') {
        addTagToRunHistory(
          {
            pathParams: { runHistoryID: id, tagID: tag.id },
          },
          {
            onSuccess: () => {
              triggerRunsRefetch();
            },
          }
        );
      }
      if (resource === 'workflow') {
        addTagToWorkflow(
          {
            pathParams: { workflowID: id, tagID: tag.id },
          },
          {
            onSuccess: () => {
              triggerWorkflowsRefetch();
            },
          }
        );
      }
    }
  };

  return (
    <TagSelector
      resourceTags={currentTags ?? []}
      allTags={tagsListToRender ?? []}
      error={tagsError}
      isLoading={isLoadingAllTags}
      addTag={addTag}
      removeTag={removeTag}
    />
  );
};

export default TagSelectionManager;
