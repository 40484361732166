import {
  GridToolbarContainer,
  GridToolbarFilterButton,
} from '@mui/x-data-grid-premium';
import { SxProps, Theme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import SearchInput from './SearchInput';
import { FilterWithDependencies } from '../../filtersManifest';
import { FilterByTagPopover } from 'components/FilterByTagPopover';
import { useFlag } from 'hooks/unleashHooks';

const containerStyle: SxProps<Theme> = {
  display: 'flex',
  alignItems: 'center',
  backgroundColor: 'grey.50',
  px: 2,
  py: 1,
  my: 2,
  borderRadius: 1,
  justifyContent: 'space-between',
};

export interface Props {
  availableFilters: FilterWithDependencies[];
  disableSearch?: boolean;
  enableFilterByTag?: boolean;
}

const Toolbar = ({
  availableFilters,
  disableSearch = false,
  enableFilterByTag = false,
}: Props): JSX.Element => {
  const tagsEnabled = useFlag('fe.tags');

  return (
    <GridToolbarContainer sx={containerStyle}>
      <Stack direction="row" spacing={1}>
        {
          availableFilters.length > 0 ? (
            <GridToolbarFilterButton
              onPointerEnterCapture={() => {}}
              onPointerLeaveCapture={() => {}}
              placeholder=""
            />
          ) : (
            <div />
          ) // We still need to render something here to keep the layout consistent
        }
        {tagsEnabled && enableFilterByTag && <FilterByTagPopover />}
      </Stack>
      {!disableSearch && (
        <SearchInput useCustomFilterModel={availableFilters.length > 0} />
      )}
    </GridToolbarContainer>
  );
};

export default Toolbar;
