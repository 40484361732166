import { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import {
  DataGridPremium,
  GridRowParams,
  useGridApiRef,
  DataGridPremiumProps,
  GridLinkOperator,
  GridSortModel,
} from '@mui/x-data-grid-premium';
import { tracking } from '@automata/utils';
import { Typography } from '@automata/ui';
import { useRouter } from 'next/router';
import { useURLFilters } from 'hooks/useURLFilters';
import FilterPanel from './components/FilterPanel';
import Toolbar from './components/Toolbar';
import { SxProps, Theme } from '@mui/material/styles';
import { Column, Row } from './types';
import { getAvailableFilters, getSortModel, verifyRows } from './services';
import { _accessColumnIds } from './columns';
import EmptyPlaceholder from 'components/EmptyPlaceholder';
import Stack from '@mui/material/Stack';
import { isTest } from '@automata/utils';
import { useURLSearchParams } from 'hooks/useURLSearchParams';

const getDynamicStyles = (
  verticalAlign?: 'top' | 'center',
  disableSelectionOnClick?: boolean
): Record<string, SxProps<Theme>> => {
  return {
    dataGrid: {
      '& .MuiDataGrid-cell': {
        alignItems: verticalAlign === 'top' ? 'flex-start' : 'center',
      },
      '& .MuiDataGrid-cell:hover': {
        color: disableSelectionOnClick ? 'inherit' : 'primary.main',
      },
      '& .MuiDataGrid-row:hover': {
        backgroundColor: disableSelectionOnClick ? 'transparent' : '#0000000A',
      },
      '& .hideRightSeparator .MuiDataGrid-columnSeparator--sideRight': {
        visibility: 'hidden',
      },
      '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {
        outline: 'none',
      },
      '& .MuiDataGrid-row': {
        cursor: disableSelectionOnClick ? 'default' : 'pointer',
        backgroundColor: 'transparent',
      },
    },
  };
};

type Data<RowShape extends object> = Omit<
  DataGridPremiumProps,
  'columns' | 'rows'
> & {
  columns: Column<RowShape>[];
  rows: Row<RowShape>[];
};

export interface ModelListProps<RowShape extends object = any> {
  data: Data<RowShape>;
  resourceName: string;
  customEmptyText?: ReactElement | null;
  route?: string;
  filterMode?: DataGridPremiumProps['filterMode'];
  disableToolbar?: boolean;
  disableSearch?: boolean;
  enableFilterByTag?: boolean;
  customHandleRowClick?: DataGridPremiumProps['onRowClick'];
  hideNoResourceGraphic?: boolean;
  verticalAlign?: 'top' | 'center';
}

export const ModelList = <RowShape extends object = any>({
  data,
  resourceName,
  customEmptyText,
  route,
  customHandleRowClick,
  filterMode = 'client',
  disableToolbar = false,
  disableSearch = false,
  enableFilterByTag = false,
  verticalAlign,
  hideNoResourceGraphic = false,
}: ModelListProps<RowShape>): JSX.Element => {
  const router = useRouter();
  const apiRef = useGridApiRef();
  const [pageSize, setPageSize] = useState(10);
  const isTestEnv = isTest();
  const { filters } = useURLFilters();
  const [searchParams] = useURLSearchParams();
  const tagFilters = searchParams.tags;

  const styles: Record<string, SxProps<Theme>> = getDynamicStyles(
    verticalAlign,
    data.disableSelectionOnClick
  );

  // runtime check of row integrity as static checks
  // here do not reject extraneous keys
  verifyRows(data.rows);

  const handleRowClick: DataGridPremiumProps['onRowClick'] = useCallback(
    ({ row }: GridRowParams<Row>) => {
      _accessColumnIds((columnIds) => {
        tracking.track('screens.row.select', { id: row[columnIds.ID] });

        if (row[columnIds.TOGGLE_EXPANSION]) {
          apiRef.current.setRowChildrenExpansion(
            row[columnIds.ID],
            !apiRef.current.getRowNode(row[columnIds.ID])?.childrenExpanded
          );
        } else if (row[columnIds.CUSTOM_ROUTE]) {
          router.push(row[columnIds.CUSTOM_ROUTE]);
        } else if (route) {
          router.push(`${route}/${row[columnIds.ID]}`);
        }
      });
    },
    [apiRef, router, route]
  );

  const availableFilters = useMemo(
    () => getAvailableFilters(data.columns),
    [data.columns]
  );

  const filterModel: DataGridPremiumProps['filterModel'] = useMemo(
    () =>
      availableFilters.length
        ? {
            linkOperator: GridLinkOperator.And,
            items: filters,
          }
        : undefined,
    [availableFilters.length, filters]
  );

  const sortModel: GridSortModel | undefined = useMemo(
    () => getSortModel(data.columns),
    [data.columns]
  );

  const noResourcesExist = useMemo(
    () =>
      !data.loading &&
      filters.length === 0 &&
      !tagFilters &&
      data.rows.length === 0,
    [data.loading, data.rows.length, filters.length, tagFilters]
  );

  // This sets initial sort model.
  // This way seems more reliable than going through initialState prop.
  useEffect(() => {
    if (sortModel) {
      apiRef?.current?.setSortModel?.(sortModel);
    }
  }, [apiRef, sortModel]);

  if (noResourcesExist && !hideNoResourceGraphic) {
    return (
      <EmptyPlaceholder alt={`empty ${resourceName}`}>
        <Stack gap={1}>
          <Typography fontWeight="bold">No {resourceName} exist yet</Typography>
          {customEmptyText ?? (
            <Typography>Click the blue + button to start</Typography>
          )}
        </Stack>
      </EmptyPlaceholder>
    );
  }

  // prevents intermittent MUI null apiRef warning
  const apiRefSafe = apiRef.current ? apiRef : undefined;

  return (
    <DataGridPremium<RowShape>
      disableVirtualization={isTestEnv}
      apiRef={apiRefSafe}
      sx={styles.dataGrid}
      pageSize={pageSize}
      onPageSizeChange={setPageSize}
      rowsPerPageOptions={[10, 20, 50, 100]}
      pagination={data.pagination ?? true}
      onRowClick={customHandleRowClick ?? handleRowClick}
      filterMode={filterMode}
      filterModel={filterModel}
      localeText={{ toolbarFilters: 'Filter by date' }}
      components={{
        Toolbar: disableToolbar ? undefined : Toolbar,
        FilterPanel,
        ColumnHeaderFilterIconButton: () => null,
      }}
      componentsProps={{
        toolbar: { availableFilters, disableSearch, enableFilterByTag },
        filterPanel: { availableFilters },
      }}
      hideFooter={data.hideFooter}
      disableSelectionOnClick
      aria-label={`${resourceName} list`}
      {...data}
    />
  );
};

export default ModelList;
