import React, { useRef, useState } from 'react';
import { Tag } from '@automata/api/apiSchemas';
import Popover from '@mui/material/Popover';
import { TagSelectionManager } from 'components/TagSelectionManager';
import { TagsListHorizontal } from '@automata/ui';
import { Box } from '@mui/system';

export type Props = {
  id: string;
  currentTags: Tag[];
  resource: 'workflow' | 'run';
  variant?: 'list' | 'default';
};

export const TagPopover = ({
  id,
  currentTags,
  resource,
  variant = 'default',
}: Props): JSX.Element | null => {
  const [open, setOpen] = useState(false);
  const tagsListRef = useRef<HTMLDivElement>(null);

  const popoverId = `tag-popover-${id}`;
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setOpen(true);
  };

  return (
    <>
      <Popover
        marginThreshold={32}
        id={popoverId}
        open={open}
        anchorEl={tagsListRef.current}
        onClose={() => {
          setOpen(false);
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box sx={{ width: '430px', p: 2 }}>
          <TagSelectionManager
            currentTags={currentTags}
            id={id}
            resource={resource}
          />
        </Box>
      </Popover>
      <Box ref={tagsListRef}>
        <TagsListHorizontal
          tags={currentTags}
          onClick={handleClick}
          variant={variant}
          limit={2}
        />
      </Box>
    </>
  );
};

export default TagPopover;
