import { GridColDef, GridRowModel } from '@mui/x-data-grid-premium';
import { ContextMenuProps } from '@automata/ui';

/*
 * We avoid accessing this directly to encourage
 * the dependancy pattern in filters
 */
export enum ValidColumnIds {
  ID = 'id',
  NAME = 'name',
  EMAIL = 'email',
  DATE = 'date',
  DATE_ACCESSED = 'dateAccessed',
  DATE_CREATED = 'dateCreated',
  DATE_MODIFIED = 'dateModified',
  TOGGLE_EXPANSION = 'toggleExpansion',
  CUSTOM_ROUTE = 'customRoute',
  AUDIT_LOGS_EVENT = 'auditLogsEvent',
  AUDIT_LOGS_EVENT_CATEGORY = 'auditLogsEventCategory',
  AUDIT_LOGS_RESOURCE_TYPE = 'auditLogsResourceType',
  AUDIT_LOGS_RESOURCE_NAME = 'auditLogsResourceName',
  DEVICES_MENU = 'devicesMenu',
  DEVICES_NOTIFICATIONS = 'devicesNotifications',
  DEVICES_ONLINE = 'devicesOnline',
  DEVICES_PATH = 'devicesPath',
  DEVICES_SERIAL = 'devicesSerial',
  DEVICES_STATUS = 'devicesStatus',
  USERS_AVATAR = 'usersAvatar',
  USERS_EMPLOYEE_ID = 'usersEmployeeId',
  USERS_MENU = 'usersMenu',
  USERS_STATUS = 'usersStatus',
  WORKFLOWS_MENU = 'workflowsMenu',
  WORKFLOWS_TYPE = 'workflowsType',
  HUB_SERIAL = 'hubSerial',
  START_TIME = 'startTime',
  STATUS = 'status',
  WORKFLOW_NAME = 'workflowName',
  RUN_START_TIME = 'runStartTime',
  RUN_DETAILS = 'runDetails',
  DEVICES_DEPLOY_CHECKED = 'devicesDeployChecked',
  DEVICES_DEPLOY_DEVICE_NAME = 'devicesDeployDeviceName',
  DEVICES_DEPLOY_NETWORK_STATUS = 'devicesDeployNetworkStatus',
  DEVICES_DEPLOY_SYNC_STATUS = 'devicesDeploySyncStatus',
  TAGS = 'tags',
}

export type Callback<OutputType> = (
  columnIds: typeof ValidColumnIds
) => OutputType;

export type ResourceCreator<OutputType> = (
  callback: Callback<OutputType>
) => OutputType;

export type Column<T extends object = any> = Omit<GridColDef<T>, 'field'> & {
  field: ValidColumnIds;
};

export type Row<T extends object = any> = GridRowModel<T> & {
  [k in ValidColumnIds]?: any;
};

type RequireKeys<T extends object, K extends keyof T> = Required<Pick<T, K>> &
  Omit<T, K>;

export type ColumnGetter<RequiredFields extends keyof Column = never> = (
  overrides: RequireKeys<Partial<Column>, RequiredFields>
) => Column;

export type MenuColumnGetter<RequiredFields extends keyof Column = never> = (
  overrides: RequireKeys<Partial<Column>, RequiredFields>,
  menuProps: Omit<ContextMenuProps, 'resourceId' | 'menuOptions'> & {
    menuOptions?: ContextMenuProps['menuOptions'];
  }
) => Column;
