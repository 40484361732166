import { ReactElement } from 'react-markdown/lib/react-markdown';
import { GridFilterPanel } from '@mui/x-data-grid-premium';
import Stack from '@mui/material/Stack';
import { FilterWithDependencies } from '../../filtersManifest';

export enum FilterIds {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  NAME = 'name',
  TAGS = 'tags',
}

export enum FilterOperators {
  CONTAINS = 'contains',
  ON_OR_AFTER = 'onOrAfter',
  ON_OR_BEFORE = 'onOrBefore',
}

interface Props {
  availableFilters: FilterWithDependencies[];
}

const FilterPanel = ({
  availableFilters,
  ...restProps
}: Props): ReactElement => {
  // return default component if no filters are available
  if (availableFilters.length === 0) return <GridFilterPanel {...restProps} />;

  return (
    <Stack>
      {availableFilters.map(({ component: Filter, key }) => (
        <Filter key={key} />
      ))}
    </Stack>
  );
};

export default FilterPanel;
