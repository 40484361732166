import { ChangeEvent, useEffect, useMemo, useState } from 'react';
import Popover from '@mui/material/Popover';
import Stack from '@mui/material/Stack';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import Badge from '@mui/material/Badge';
import {
  ErrorDisplay,
  Spinner,
  TagFilterButton,
  TrackedButton,
  Typography,
  SearchField,
} from '@automata/ui';
import { Tag, Uuid } from '@automata/api/apiSchemas';
import { useGetWorkspaceTags } from '@automata/api/apiComponents';
import { useWorkspaceID } from 'hooks/useWorkspaceID';
import { useURLSearchParams } from 'hooks/useURLSearchParams';
import { FilterIds } from 'components/ModelList/components/FilterPanel';

export const FilterByTagPopover = (): JSX.Element => {
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [filteredTags, setFilteredTags] = useState<Tag[]>([]);
  const workspaceID = useWorkspaceID();
  const [searchParams, updateValue] = useURLSearchParams();
  const appliedTags = useMemo(
    () =>
      searchParams[FilterIds.TAGS]
        ? searchParams[FilterIds.TAGS].split(',')
        : [],
    [searchParams]
  );
  const [selectedTags, setSelectedTags] = useState<Uuid[]>(appliedTags);

  useEffect(() => {
    setSelectedTags(appliedTags);
  }, [appliedTags]);

  const {
    data: allTags,
    isLoading: isLoadingAllTags,
    error: tagsError,
  } = useGetWorkspaceTags(
    {
      pathParams: {
        workspaceID: workspaceID ?? '',
      },
    },
    {
      enabled: Boolean(workspaceID),
      onSuccess: (data) => {
        setFilteredTags(data.tags.filter((tag) => !tag.is_archived));
      },
    }
  );

  const handleOpenPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleSearchChange = (value: string) => {
    setFilteredTags(
      allTags?.tags.filter((tag) =>
        tag.name.toLowerCase().includes(value.trim().toLowerCase())
      ) ?? []
    );
  };

  const handleTagToggle = (event: ChangeEvent<HTMLInputElement>) => {
    const tagID = event.target.value;
    if (selectedTags.includes(tagID)) {
      setSelectedTags(
        selectedTags.filter((selectedTag) => selectedTag !== tagID)
      );
    } else {
      setSelectedTags([...selectedTags, tagID]);
    }
  };

  const handleApplyFilter = () => {
    setAnchorEl(null);
    updateValue({
      [FilterIds.TAGS]: selectedTags.join(','),
    });
    setFilteredTags(allTags?.tags ?? []);
  };

  return (
    <>
      <Stack direction="row" alignItems="center">
        <Badge
          badgeContent={appliedTags.length}
          color="primary"
          max={99}
          overlap="circular"
        >
          <TagFilterButton onClick={handleOpenPopover} />
        </Badge>
      </Stack>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            width: '372px',
            padding: 2,
          },
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Stack gap={2}>
          <Typography variant="subtitle1">Filter by tag</Typography>
          <SearchField
            onChange={handleSearchChange}
            placeholder="Search tags"
            variant="outlined"
          />
          <Stack gap={1}>
            <Typography variant="subtitle2">Tags</Typography>
            <Stack height="166px" overflow="scroll">
              {isLoadingAllTags && <Spinner />}
              {tagsError && (
                <ErrorDisplay
                  error={tagsError}
                  userMessage="Unable to fetch tags list"
                />
              )}
              {!isLoadingAllTags && !tagsError && filteredTags.length === 0 && (
                <Stack
                  justifyContent="center"
                  alignItems="center"
                  height="100%"
                >
                  <Typography variant="subtitle2">No tags found</Typography>
                </Stack>
              )}
              {!isLoadingAllTags && !tagsError && filteredTags.length > 0 && (
                <FormGroup>
                  {filteredTags.map((tag) => (
                    <FormControlLabel
                      key={tag.id}
                      control={
                        <Checkbox
                          disableRipple
                          checked={selectedTags.includes(tag.id)}
                          onChange={handleTagToggle}
                          name={tag.name}
                          value={tag.id}
                          sx={{ py: 0.5, pl: 2 }}
                        />
                      }
                      label={tag.name}
                    />
                  ))}
                </FormGroup>
              )}
            </Stack>
          </Stack>
          <Box>
            <TrackedButton
              trackLabel="workflowsList.filterByTag.apply"
              onClick={handleApplyFilter}
              variant="outlined"
            >
              Apply filter
            </TrackedButton>
          </Box>
        </Stack>
      </Popover>
    </>
  );
};

export default FilterByTagPopover;
